import { lazy, Suspense } from "react";
import { Switch, Route, Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Affix } from "antd";
import { ButtonWA, Stores, StoresContainer } from "./fixedButtons";
import { SvgIcon } from "../common/SvgIcon";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />

      <Affix>
      <Header />
      </Affix>
      
      <Switch>
        <Route
          path="/payment"    
          exact           
          component={lazy(() => import(`../pages/Payments`))}  
        />
        <Route
          path="/payment/status"    
          exact           
          component={lazy(() => import(`../pages/PaymentsStatus`))}  
        />
        <Route
          path="/code"    
          exact           
          component={lazy(() => import(`../pages/Code`))}  
        />
         <Route
          path="/tos"
          exact
          component={lazy(() => import(`../pages/TermsAndServices`))}
        />
        <Route
          path="/privacy"
          exact
          component={lazy(() => import(`../pages/PrivacyPolicy`))}
        />
        <Route
          path="/faqbuyer"
          exact
          component={lazy(() => import(`../pages/FAQBuyer`))}
        />
        <Route
          path="/faqseller"
          exact
          component={lazy(() => import(`../pages/FAQSeller`))}
        />
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Affix>
        <Footer />
      </Affix>
    </Suspense>
  );
};

export default Router;
